import React, { useContext, useEffect, useState } from 'react';
import { BusinessDefault, cardDefault, UserDefault } from '../../../constants/model';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { getStorageUser } from '../../util/UserUtil';
import { BusinessCardView, BusinessRegisterView, BusinessSubscriptionView, InputRegister } from '../RegisterView';

const ProfileUserView = () => {
    const [item, setItem] = useState({ ...UserDefault });
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        let user = getStorageUser();
        setItem(user.usuario)
    }, [])

    const sendRequest = () => {
        if (item.username.length > 0 && item.phone.length > 0 && item.fullname.length > 0) {
            HttpConnection(`${HttpOperations.users}/${item.id}`,
                result => {
                    setItem(result);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => console.log(error), HttpMethod.put, item)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    const onchange = (e) => setItem({ ...item, [e.target.name]: e.target.value, });

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" px="12">
            <Card flex shadowOn width="12" pv="20" ph="24" align="flex-end">
                <Container width="12" mh="8" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgUserData" />} />
                <Container width="12" mh="8" mb="16" fontColor="color-gray" children={<TextLocalized children="msgUserDataText" />} />

                <ContainerFlex width="12">
                    <Container width="12" lg="6" ph="8">
                        <InputRegister label="msgEmail" name="username" value={item.username} onChange={onchange} />
                    </Container>
                    <Container width="12" lg="6" ph="8">
                        <InputRegister label="msgPhone" name="phone" value={item.phone} onChange={onchange} />
                    </Container>
                </ContainerFlex>
                <Container width="12" ph="8">
                    <InputRegister label="msgFullName" name="fullname" value={item.fullname} onChange={onchange} />
                </Container>
                <Button onClick={sendRequest} mt="16" mh="8" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdateUser" />} />
            </Card>
        </Container>
    </ ContainerFlex>
}

const ProfilePassView = () => {
    const [password, setPasword] = useState("");
    const [repassword, setRepasword] = useState("");

    const { dispatch } = useContext(UserContext);

    const sendRequest = () => {
        if (password !== "" && password === repassword) {
            HttpConnection(`${HttpOperations.users}/password`,
                result => {
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => console.log(error), HttpMethod.put, { password: password })
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" px="12">
            <Card flex shadowOn width="12" pv="20" ph="24" align="flex-end">
                <Container width="12" mh="8" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgPassword" />} />
                <Container width="12" mh="8" mb="16" fontColor="color-gray" children={<TextLocalized children="msgUserPassText" />} />

                <Container width="12" lg="6" ph="8">
                    <InputRegister label="msgPassword" name="password" value={password} onChange={e => setPasword(e.target.value)} />
                </Container>
                <Container width="12" lg="6" ph="8">
                    <InputRegister label="msgPassword" name="password" value={repassword} onChange={e => setRepasword(e.target.value)} />
                </Container>
                <Button onClick={sendRequest} mt="16" mh="8" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdatePas" />} />
            </Card>
        </Container>
    </ ContainerFlex>
}

const ProfileBusinessView = () => {
    const [item, setItem] = useState({ ...BusinessDefault });
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        let user = getStorageUser();

        HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}`,
            result => setItem(result),
            error => console.log(error), HttpMethod.get);
    }, [])

    const sendRequest = () => {
        if (item.descripcion !== "" && item.razon !== "" && item.cif !== "" &&
            item.direccion !== "" && item.cp !== "" && item.localidad !== "" &&
            item.provincia !== "" && item.pais !== "" && item.telefono !== "" && 
            item.contacto !== "" && item.diasHistorico
        ) {
            let user = getStorageUser();

            HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}`,
                result => {
                    setItem(result);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => console.log(error), HttpMethod.put, item)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" px="12">
            <Card flex shadowOn width="12" pv="20" ph="24" align="flex-start">
                <Container width="12" mh="8" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgEntityData" />} />
                <Container width="12" mh="8" mb="16" fontColor="color-gray" children={<TextLocalized children="msgEntityDataText" />} />

                <BusinessRegisterView formData={item} setFormData={setItem} />
                <Button onClick={sendRequest} mt="16" mh="8" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdateBusi" />} />
            </Card>
        </Container>
    </ ContainerFlex>
}

const ProfileCardView = () => {
    const [item, setItem] = useState({ ...cardDefault });
    const [empresa, setEmpresa] = useState();

    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        let user = getStorageUser();
        HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}`,
            result => {
                setEmpresa(result);
                setItem(result.tarjetas[0]);
            },
            error => console.log(error), HttpMethod.get);
    }, [])

    const sendRequest = () => {
        if (item.titular !== "" && item.numero !== "" && item.expMonth !== "" &&
            item.expYear !== "" && item.cvc !== "") {

            HttpConnection(`${HttpOperations.bussiness}/${empresa.id}/suscripcion/${empresa.suscripcion.id}`,
                result => {
                    setItem(result);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => console.log(error), HttpMethod.put, item)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" px="12">
            <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgPaymentMethod" />} />
                <Container width="12" mb="16" fontColor="color-gray" children={<TextLocalized children="msgPaymentDataText" />} />

                <BusinessCardView formData={item} setFormData={setItem} />
                <Button onClick={sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdatePay" />} />
            </Card>
        </Container>
    </ ContainerFlex>
}

const ProfileSubscriptionView = () => {
    const [item, setItem] = useState(0);
    const [empresa, setEmpresa] = useState();

    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        let user = getStorageUser();

        HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}`,
            result => {
                setEmpresa(result);
                setItem(result.suscripcion.id)
            },
            error => console.log(error), HttpMethod.get);
    }, [])

    const sendRequest = () => {
        if (item !== 0) {
            HttpConnection(`${HttpOperations.bussiness}/${empresa.id}/suscripcion/${item}`,
                result => {
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => console.log(error), HttpMethod.put)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" px="12">
            <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgSubscriptionType" />} />
                <Container width="12" mb="16" fontColor="color-gray" children={<TextLocalized children="msgSubscriptionText" />} />

                <BusinessSubscriptionView formData={item} setFormData={setItem} />
                <Button onClick={sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdateSub" />} />
            </Card>
        </Container>
    </ ContainerFlex>
}

const ProfileView = () => {
    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgProfile" />} />
        </ContainerFlex>
        <ContainerFlex width="12" justify="center">
            <Container width="5">
                <ProfileUserView />
                <ProfilePassView />
                <ProfileBusinessView />
            </Container>
            <Container width="5">
                <ProfileCardView />
                <ProfileSubscriptionView />
            </Container>
        </ContainerFlex>

    </Container>

};

export default ProfileView;