import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Container, ContainerFlex, Card, TextLocalized, SpinnerLoader } from '../../styled/Styled';
import { HttpConnection, HttpImageConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { format } from 'date-fns'
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiInformation } from '@mdi/js';

import DatePicker from "react-datepicker";

import '../../../css/calendar.css';
import { PointDefault } from '../../../constants/model';

const PointFormView = ({ formData, setFormData, isSending, dateStart, setDateStart, datePrevious, setDatePrevious, sendRequest }) => {

    const _changeDateStart = (date) => {
        setDateStart(date);
        setFormData({ ...formData, fechaInicio: date.getTime() });
    }

    const _changeDatePrevious = (date) => {
        setDatePrevious(date);
        setFormData({ ...formData, citaPrevia: date.getTime() });
    }

    const _changeType = (e) => setFormData({ ...formData, tipo: { id: e.target.value } });
    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    return <Container width="12" lg="4" px="12">
        <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
            <Container width="wrap">
                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgPoint" />} />
                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgNewPointText" />} />
            </Container>
            <ContainerFlex width="12" pt="16" justify="flex-end">

                <ContainerFlex width="12">
                    <Container width="12">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLocationStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="localizacion" value={formData.localizacion} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgType" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <select name="tipo" value={formData.tipo.id ?? ""} onChange={_changeType} style={{ padding: "8px" }}>
                        <option children="Selecciona" />
                        <option value={1} children="Carga" />
                        <option value={2} children="Descarga" />
                        <option value={3} children="Carga-Descarga" />
                        <option value={4} children="Enganche" />
                        <option value={5} children="Desenganche" />
                        <option value={6} children="Tránsito" />
                    </select>
                </Card>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={dateStart}
                                minDate={new Date()}
                                onChange={_changeDateStart}
                                className="input-date"
                                name="fechaInicio"
                                value={dateStart ?? ""}
                            />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgPreviousDate" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="citaPrevia" value={formData.citaPrevia} onChange={_onChange} style={{ padding: "8px" }} />

                            {
                                /*
                                                            <DatePicker
                                locale="es"
                                dateFormat="HH:mm"
                                showTimeSelect
                                showTimeSelectOnly
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={datePrevious}
                                onChange={_changeDatePrevious}
                                className="input-date"
                                name="citaPrevia"
                                value={datePrevious ?? ""}
                            />
                                */
                            }

                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPaletsEnt" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numPaletsEntrega" value={formData.numPaletsEntrega} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPaletsRec" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numPaletsRecogida" value={formData.numPaletsRecogida} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgExchange" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <select name="intercambio" value={formData.intercambio ?? ""} onChange={_onChange} style={{ padding: "8px" }}>
                        <option children="Selecciona" />
                        <option value={true} children="Si" />
                        <option value={false} children="No" />
                    </select>
                </Card>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgObservations" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <textarea rows="5" name="observaciones" value={formData.observaciones ?? ""} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>
                {
                    isSending ? <Container mt="16" children={<SpinnerLoader />} />
                        : <Button onClick={sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSend" />} />
                }
            </ContainerFlex>
        </Card>
    </Container>
};

const PointUserInfoView = ({ item }) => {
    return <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
        <Container width="wrap">
            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgPointUserInfo" />} />
            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgPointUserInfoText" />} />
        </Container>
        <ContainerFlex width="12" pt="16" justify="flex-end">
            <ContainerFlex width="12">
                <Container width="6" pr="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateStart" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.fechaRealInicio ? format(new Date(item.fechaRealInicio), "dd/MM/yyyy HH:mm") : "XX-XX-XXX"} />
                </Container>
                <Container width="6" pl="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateEnd" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.fechaRealFin ? format(new Date(item.fechaRealFin), "dd/MM/yyyy HH:mm") : "XX-XX-XXX"} />
                </Container>
            </ContainerFlex>

            <ContainerFlex width="12">
                <Container width="6" pr="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateStart" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.fechaRealInicioActividad ? format(new Date(item.fechaRealInicioActividad), "dd/MM/yyyy HH:mm") : "XX-XX-XXX"} />
                </Container>
                <Container width="6" pl="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateEnd" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.fechaRealFinActividad ? format(new Date(item.fechaRealFinActividad), "dd/MM/yyyy HH:mm") : "XX-XX-XXX"} />
                </Container>
            </ContainerFlex>

            <ContainerFlex width="12">
                <Container width="12">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumCMR" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.cmr ?? "--"} />
                </Container>
            </ContainerFlex>

            <ContainerFlex width="12">
                <Container width="6" pr="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPaletsEnt" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.numPaletsEntregados ?? "--"} />
                </Container>
                <Container width="6" pl="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPaletsRec" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.numPaletsRecogidos ?? "--"} />
                </Container>
            </ContainerFlex>

            <ContainerFlex width="12">
                <Container width="6" pr="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLatitude" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.latitud ?? "--"} />
                </Container>
                <Container width="6" pl="4">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLongitude" />} mv="8" />
                    <Card width="12" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.longitud ?? "--"} />
                </Container>
            </ContainerFlex>

            <ContainerFlex width="12">
                <Container width="12">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgIncidence" />} mv="8" />
                    <Card width="12" minheight="100px" px="8" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px" children={item.incidencias} />
                </Container>
            </ContainerFlex>
        </ContainerFlex>
    </Card>
}

const PointFileItem = ({ item }) => {
    const _show = () => {
        HttpImageConnection(`${HttpOperations.point}/${item.idPuntoIntermedio}/base64/${item.id}`,
            result => {
                const base64ImageData = `data:image/png;base64,${result}`;
                const contentType = 'image/png';

                const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, { type: contentType });
                const blobUrl = URL.createObjectURL(blob);

                window.open(blobUrl, '_blank');

            }, error => console.log(error), HttpMethod.get)
    }

    return <Container width="12">
        <ContainerFlex align="flex-start" ph="32" pv="10">
            <Container width="wrap">
                <Container fontWeight="500" fontSize="20px" children={`${item.nombre}`} />
                <Container fontWeight="500" fontSize="10px" children={`${format(new Date(item.fecha), "dd/MM/yyyy HH:mm")}`} />
            </Container>
            <Button onClick={_show} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
        </ContainerFlex>
    </Container>
}

const PointFilesView = ({ item }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(`${HttpOperations.point}/${item.id}/${HttpOperations.files}`,
            result => {
                console.log(result)
                setItems(result);
            },
            error => console.log(error), HttpMethod.get)
    }, [item.id])

    return <Card flex shadowOn width="12" pv="20" mv="20" align="flex-end">
        <Container width="wrap" ph="32">
            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgFiles" />} />
            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgFilesText" />} />
        </Container>
        <Container width="12" color="color-gray-dark" height="1px" mv="20" />
        <ContainerFlex width="12" justify="flex-end">
            {items.length > 0 ? items.map((item) => <PointFileItem key={item.id} item={item} />) : <Container width="12" textAlign="center" fontSize="20px" children={<TextLocalized children="msgEmptyData" />} />}
        </ContainerFlex>
    </Card>
}

const PointDetailView = () => {
    const [formData, setFormData] = useState();
    const [isSending, setIsSending] = useState(false);

    const [dateStart, setDateStart] = useState("");
    const [datePrevious, setDatePrevious] = useState("");

    const { dispatch } = useContext(UserContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (location.state.item.id) {
            HttpConnection(`${HttpOperations.point}/${location.state.item.id}`,
                result => _setDefaultValues(result),
                error => console.log(error), HttpMethod.get)
        } else {
            _setDefaultValues(location.state.item);
        }
    }, [location.state.item])

    const _setDefaultValues = (result) => {
        console.log(result)
        if (result.fechaInicio !== "") {
            setDateStart(new Date(result.fechaInicio));
        }

        /*
        if (result.citaPrevia && result.citaPrevia !== "") {
            let date = new Date();
            let pick = result.citaPrevia.split(":");
            date.setHours(pick[0]);
            date.setMinutes(pick[1]);
            setDatePrevious(date)
        }
        */

        setFormData(result);
    }

    const formRequest = () => {
        if (formData.localizacion !== "" && formData.tipo && formData.tipo.id &&
            formData.idParteOperaciones !== "" && formData.fechaInicio !== "" &&
            formData.numPaletsEntrega !== "" && formData.numPaletsRecogida !== "" &&
            formData.intercambio !== "") {

            setIsSending(true);
            HttpConnection(formData.id ? `${HttpOperations.point}/${formData.id}` : `${HttpOperations.order}/${formData.idParteOperaciones}/${HttpOperations.point}`,
                result => {
                    console.log(result)
                    setIsSending(false);

                    //Set default
                    if (!formData.id) {
                        setFormData({ idParteOperaciones: formData.idParteOperaciones, ...PointDefault });
                        setDateStart("");
                        setDatePrevious("");
                    }

                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => {
                    console.log(error)
                    setIsSending(false);
                }, formData.id ? HttpMethod.put : HttpMethod.post, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <ContainerFlex width="12" lg="wrap" justify="flex-end">
                <Button onClick={() => history(-1)} flex justify="center" align="center" px="10" mh="32" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" maxwidth="50px" height="50px" radius="50%" children={<Icon path={mdiChevronLeft} size="30px" />} />
            </ContainerFlex>
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgPoint" />} />
            <Container width="12" lg="wrap" />
        </ContainerFlex>

        <ContainerFlex width="12" justify="center" align="top" pv="8">
            {
                formData ?
                    <Fragment>
                        <PointFormView formData={formData} setFormData={setFormData} isSending={isSending} dateStart={dateStart} setDateStart={setDateStart} datePrevious={datePrevious} setDatePrevious={setDatePrevious} sendRequest={formRequest} />
                        {
                            formData.id ? <Container width="12" lg="6" px="12">
                                <PointUserInfoView item={formData} />
                                <PointFilesView item={formData} />
                            </Container> : null
                        }
                    </Fragment>
                    : null
            }
        </ContainerFlex>

    </Container>
};

export default PointDetailView;