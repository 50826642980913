import React, { useReducer } from 'react';
import { notificationDefault } from '../util/NotificationUtil';
import { getStorageUser, saveStorageUser } from '../util/UserUtil';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

export const UserData = {
    user: "SAVE_US_MET"
}

const initState = {
    login: getStorageUser() ? true : false,
    notification: { ...notificationDefault },
    modal: { show: false, body: <div /> },
}

const UserAction = {
    login: "LOGIN",
    logout: "LOGOUT",
    showNoti: "SHOWNOTI",
    showModal: "SHOWMODAL",
}

const reduce = (state, callback) => {
    switch (callback.action) {
        case UserAction.login:
            saveStorageUser(callback.data);
            return { ...state, login: true, space: null };
        case UserAction.logout:
            localStorage.removeItem(UserData.user)
            return { ...state, login: false, };
        case UserAction.showNoti:
            return { ...state, notification: callback.data }
        case UserAction.showModal:
            return { ...state, modal: callback.data }
        default:
            return state
    }
}

const UserProvider = (props) => {
    const [state, dispatch] = useReducer(reduce, initState);
    let value = { state, dispatch };
    return <UserContext.Provider value={value} children={props.children} />;
}

export { UserContext, UserProvider, UserConsumer, UserAction }