import React from 'react';
import { Button, ContainerFlex, TextLocalized } from '../styled/Styled';

const Footer = () => {
    return (
        <ContainerFlex width="12" justify="center" align="center" ph="32" pv="48" fontSize="15px" fontColor="color-gray">
            <Button onClick={() => window.open("https://metotrans.com/politica-de-privacidad/", "_blank")} shadowOn={false} fontHoverColor="color-black" mh="8" children={<TextLocalized children="msgPrivacy" />} />
            <Button onClick={() => window.open("https://metotrans.com/contacto/", "_blank")} shadowOn={false} fontHoverColor="color-black" mh="8" children={<TextLocalized children="msgContact" />} />
            <Button shadowOn={false} fontHoverColor="color-black" mh="8" children={<TextLocalized children="msgCopy" />} />
        </ContainerFlex>
    );
};

export default Footer;