import './css/normalize.css';
import './css/bootstrap-grid.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Route, useNavigate, useLocation, Routes, Navigate } from 'react-router-dom';
import { Fragment, useContext, useEffect, useState } from 'react';

import Header from './components/views/Header';
import Footer from './components/views/Footer';
import { NotificationView } from './components/util/NotificationUtil';


import LoginView from './components/views/LoginView';
import RegisterView from './components/views/RegisterView';
import { Button, Container, ContainerFlex, TextLocalized } from './components/styled/Styled';
import OrderListView from './components/views/orders/OrderListView';
import UserListView from './components/views/users/UserListView';
import UserDetailView from './components/views/users/UserDetailView';
import NoteListView from './components/views/notes/NoteListView';
import NoteDetailView from './components/views/notes/NoteDetailView';
import OrderDetailView from './components/views/orders/OrderDetailView';
import PointDetailView from './components/views/points/PointDetailView';
import ModalView from './components/util/ModalUtil';
import ProfileView from './components/views/profile/ProfileView';

const MenuView = () => {
  const [index, setIndex] = useState(0);
  const getColor = (indexNew) => index === indexNew ? "color-primary-dark" : "color-primary"

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname.includes("user")) setIndex(0)
    else if (location.pathname.includes("note")) setIndex(1)
    else if (location.pathname.includes("profile")) setIndex(3)
    else setIndex(2)
  }, [location.pathname])

  return <ContainerFlex width="12" justify="center" align="center">
    <Button onClick={() => history("/")} ph="32" pv="12" color={getColor(0)} hoverColor="color-primary-dark" fontColor="color-white" radius="30px" radiustr="0" radiusbr="0" children={<TextLocalized children="msgUsers" />} />
    <Button onClick={() => history("/notes")} ph="32" pv="12" color={getColor(1)} hoverColor="color-primary-dark" fontColor="color-white" radius="0" children={<TextLocalized children="msgNotes" />} />
    <Button onClick={() => history("/orders")} ph="32" pv="12" color={getColor(2)} hoverColor="color-primary-dark" fontColor="color-white" radius="0" children={<TextLocalized children="msgOrders" />} />
    <Button onClick={() => history("/profile")} ph="32" pv="12" color={getColor(3)} hoverColor="color-primary-dark" fontColor="color-white" radius="30px" radiustl="0" radiusbl="0" children={<TextLocalized children="msgProfile" />} />

  </ContainerFlex>
}

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" minheight="100vh" color="color-white">
      <Router basename="/">
        <Header />
        {
          state.login ?
            <Fragment>
              <MenuView />
              <Routes>
                <Route exact={true} path="/" element={<UserListView />} />
                <Route exact={true} path="/notes" element={<NoteListView />} />
                <Route exact={true} path="/orders" element={<OrderListView />} />

                <Route exact={true} path="/user" element={<UserDetailView />} />
                <Route exact={true} path="/note" element={<NoteDetailView />} />
                <Route exact={true} path="/order" element={<OrderDetailView />} />
                <Route exact={true} path="/point" element={<PointDetailView />} />

                <Route exact={true} path="/profile" element={<ProfileView />} />
                
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Fragment>

            :
            <Routes>
              <Route exact={true} path="/" element={<LoginView />} />
              <Route exact={true} path="/register" element={<RegisterView />} />
              <Route path="*" element={<Navigate to="/" replace />} />

            </Routes>
        }
        <Footer />
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />

export default App;
