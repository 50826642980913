import React, { Fragment } from 'react';
import { Button, Container, ContainerFlex, TextLocalized } from '../styled/Styled';
import logo from '../../images/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserAction, UserContext } from '../providers/UserProvider';

const Header = () => {
    const { state, dispatch } = useContext(UserContext);
    let history = useNavigate();
    let location = useLocation();

    const _logOut = () => {
        dispatch({ action: UserAction.logout });
        history("/");
    }

    return (
        <ContainerFlex width="12" justify="space-between" align="center" ph="32" pv="48">
            <Container width="wrap" />
            <img alt="logo" src={logo} height="40px" />
            <Container width="wrap" textAlign="right" fontColor="color-gray" fontSize="13px">
                {
                    state.login ?
                        <Fragment>
                            <TextLocalized children="msgWantOut" />
                            <Button onClick={_logOut} shadowOn={false} fontColor="color-black" children={<TextLocalized children="actionLogout" />} />
                        </Fragment>
                        : location.pathname === "/" ?
                            <Fragment>
                                <TextLocalized children="msgAreNew" />
                                <Button onClick={() => history('./register')} shadowOn={false} fontColor="color-black" children={<TextLocalized children="actionCreateAccount" />} />
                            </Fragment>
                            : null
                }
            </Container>
        </ContainerFlex>
    );
};

export default Header;