
import { mdiAccountOffOutline, mdiAccountOutline, mdiInformation, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserDefault } from '../../../constants/model';
import { LanguageContext } from '../../providers/LanguageProvider';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, SpinnerLoader, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { ModalViewDefault } from '../../util/ModalUtil';
import { getStorageUser } from '../../util/UserUtil';

const UserMemberItemView = ({ item, show, toggle, deleteItem }) => {
    const [isSending, setIsSending] = useState(false);
    const languageContext = useContext(LanguageContext);

    const toggleSending = (value) => setIsSending(value);

    return <ContainerFlex align="flex-start" ph="32" pv="10">
        <Container width="wrap">
            <Container fontWeight="500" fontSize="20px" children={`${item.empresas[0].fullname}`} />
            {
                item.type.id === 2 ?
                    <ContainerFlex width="12" fontSize="14px" fontColor="color-gray" mt="8" >
                        <Container width="6" children={`Nº tlf: ${item.phone}`} />
                        <Container width="6" children={`${languageContext.dictionary["msgDNI"]}: ${item.username}`} />
                    </ContainerFlex>
                    : null
            }
        </Container>
        {
            isSending ? <ContainerFlex minwidth="136px" justify="center" children={<SpinnerLoader />} />
                : <Fragment>
                    <Button onClick={() => show(item)} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
                    {
                        item.empresas[0].idUserStatus === 1 ?
                            <Button onClick={() => toggle(item, toggleSending)} px="10" mh="8" color="color-green" hoverColor="color-green-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiAccountOutline} size="20px" />} />
                            : item.empresas[0].idUserStatus === 2 ?
                                <Button onClick={() => toggle(item, toggleSending)} px="10" mh="8" color="color-yellow" hoverColor="color-yellow-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiAccountOffOutline} size="20px" />} />
                                : null
                    }
                    <Button onClick={() => deleteItem(item, toggleSending)} px="10" color="color-red" hoverColor="color-red-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiTrashCanOutline} size="20px" />} />
                </Fragment>
        }
    </ContainerFlex>
}

const UserManager = () => {
    const [formData, setFormData] = useState({ ...UserDefault });
    const [search, setSearch] = useState("");

    const [isSending, setIsSending] = useState(false);

    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);
    const languageContext = useContext(LanguageContext);

    let history = useNavigate();

    useEffect(() => {
        _refreshData()
    }, [])

    const _refreshData = () => HttpConnection(HttpOperations.users,
        result => setItems(result.filter(item => item.type.id === 2).sort((itemA, itemB) => itemA.fullname < itemB.fullname ? -1 : 1)),
        error => console.log(error), HttpMethod.get)

    const createUserRequest = () => {
        if (formData.username.length > 0 && formData.phone.length > 0 && formData.fullname.length > 0) {
            setIsSending(true);
            //Set default pass
            //formData.password = 123;

            let user = getStorageUser();

            HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}/user`,
                result => {
                    setIsSending(false);
                    setFormData({ ...UserDefault });
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                    _refreshData();
                },
                error => {
                    setIsSending(false);
                    console.log(error)
                }, HttpMethod.post, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    const toggleUser = (user, toggleSending) => {
        toggleSending(true);
        HttpConnection(`${HttpOperations.users}/toggle/${user.id}`,
            () => {
                toggleSending(false);
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                _refreshData();
            },
            error => {
                toggleSending(false);
                dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorDeleteUser" } });
                console.log(error)
            }, HttpMethod.patch)
    }

    const deleteUser = (user, toggleSending) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => {
            toggleSending(true);
            HttpConnection(`${HttpOperations.users}/${user.id}`,
                () => {
                    toggleSending(false);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                    _refreshData();
                },
                error => {
                    toggleSending(false);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorDeleteUser" } });
                }, HttpMethod.delete)
        }
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const searchUser = () => HttpConnection(`${HttpOperations.users}/username/${search}`,
        result => {
            console.log(result)
            setItems([result])
        },
        error => {
            console.log(error)
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorSearchUser" } })
        }, HttpMethod.get)

    const cleanSearch = () => {
        setSearch("");
        _refreshData();
    }

    const showUser = (user) => history("/user", { state: { user: user } });
    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    return <ContainerFlex width="12" justify="center" align="top" pv="8">
        <Container width="12" lg="4" px="12">
            <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
                <Container width="wrap">
                    <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgNewUser" />} />
                    <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgNewUserText" />} />
                </Container>
                <ContainerFlex width="12" pt="16" justify="flex-end">
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgFullName" />} mv="8" />
                    <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                        <input name="fullname" value={formData.fullname} onChange={_onChange} style={{ padding: "8px" }} />
                    </Card>
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDNI" />} mv="8" />
                    <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                        <input name="username" value={formData.username} onChange={_onChange} style={{ padding: "8px" }} />
                    </Card>
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgPhoneCode" />} mv="8" />
                    <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                        <input name="phone" value={formData.phone} onChange={_onChange} style={{ padding: "8px" }} />
                    </Card>
                    <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgMatricula" />} mv="8" />
                    <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                        <input name="registration" value={formData.registration} onChange={_onChange} style={{ padding: "8px" }} />
                    </Card>
                    {
                        isSending ? <Container mt="16" children={<SpinnerLoader />} />
                            : <Button onClick={createUserRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark"
                                textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionAddUser" />} />
                    }
                </ContainerFlex>
            </Card>
        </Container>

        <Container width="12" lg="6" px="12">
            <Card shadowOn width="12">
                <ContainerFlex flex width="12" pv="20" ph="32" align="flex-start">
                    <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgUsers" />} />
                    <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgUsersText" />} />

                    <ContainerFlex width="12" pt="10">
                        <Card border="solid" borderColor="color-primary" borderWidth="1px" width="wrap" children={<input value={search} onChange={e => setSearch(e.target.value)} placeholder={languageContext.dictionary["msgDNI"]} style={{ padding: "8px" }} />} />
                        <Button onClick={searchUser} ml="8" mr="4" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSearch" />} />
                        <Button onClick={cleanSearch} mh="4" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionClean" />} />
                    </ContainerFlex>
                </ContainerFlex>
                <Container width="12" color="color-gray-dark" height="1px" />
                <Container width="12" pv="10" children={items.map((item, index) => <UserMemberItemView key={item.id} item={item} show={showUser} toggle={toggleUser} deleteItem={deleteUser} />)} />
            </Card>
        </Container>
    </ContainerFlex>
}

const UserListView = () => {

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgUsers" />} />
        </ContainerFlex>

        <UserManager />
    </Container>
};

export default UserListView;