import { mdiChevronLeft, mdiInformation, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Container, ContainerFlex, Card, TextLocalized, SpinnerLoader } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { format } from 'date-fns'

import DatePicker from "react-datepicker";

import '../../../css/calendar.css';
import { OrderDefault, PointDefault } from '../../../constants/model';
import { ModalViewDefault } from '../../util/ModalUtil';

export const OrderFormView = ({ formData, setFormData, isSending, dateStart, setDateStart, dateEnd, setDateEnd, datePrevious, setDatePrevious, sendRequest }) => {

    const _changeDateStart = (date) => {
        setDateStart(date);
        setFormData({ ...formData, fechaInicio: date.getTime() });
    }
    const _changeDateEnd = (date) => {
        setDateEnd(date);
        setFormData({ ...formData, fechaFin: date.getTime() });
    }

    const _changeDatePrevious = (date) => {
        setDatePrevious(date);
        setFormData({ ...formData, citaPrevia: date.getTime() });
    }

    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    return <Container width="12" lg="4" px="12">
        <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
            <Container width="wrap">
                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgOrder" />} />
                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgNewOrderText" />} />
            </Container>
            <ContainerFlex width="12" pt="16" justify="flex-end">

                <ContainerFlex width="12">
                    <Container width="6" pr="4" display={formData.id && formData.idNotaCarga ? "none" : "block"}>
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNote" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="idNotaCarga" value={formData.idNotaCarga} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgIdentifier" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input name="identificador" value={formData.identificador} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={dateStart}
                                minDate={new Date()}
                                onChange={_changeDateStart}
                                className="input-date"
                                name="fechaInicio"
                                value={dateStart}
                            />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateEnd" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={dateEnd}
                                minDate={new Date()}
                                onChange={_changeDateEnd}
                                className="input-date"
                                name="fechaFin"
                                value={dateEnd}
                            />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLocationStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="origen" value={formData.origen} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLocationEnd" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="destino" value={formData.destino} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgTemperature" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="temperatura" value={formData.temperatura} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPalets" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numPalets" value={formData.numPalets} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgExchange" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <select name="intercambio" value={formData.intercambio ?? ""} onChange={_onChange} style={{ padding: "8px" }}>
                                <option children="Selecciona" />
                                <option value={true} children="Si" />
                                <option value={false} children="No" />
                            </select>
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgPreviousDate" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="HH:mm"
                                showTimeSelect
                                showTimeSelectOnly
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={datePrevious}
                                onChange={_changeDatePrevious}
                                className="input-date"
                                name="citaPrevia"
                                value={datePrevious ?? ""}
                            />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgMat1" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="matriculaPrimera" value={formData.matriculaPrimera} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgMat2" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="matriculaSegunda" value={formData.matriculaSegunda} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgObservations" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <textarea rows="5" name="observaciones" value={formData.observaciones ?? ""} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>
                {
                    isSending ? <ContainerFlex mt="16" align="flex-end" children={<SpinnerLoader size="41px" />} />
                        : <Button onClick={sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSend" />} />

                }
            </ContainerFlex>
        </Card>
    </Container >
}

const DriverSetView = ({ item, setItem }) => {
    const [driverId, setDriverId] = useState();
    const [isSending, setIsSending] = useState(false);
    const [items, setItems] = useState([])
    const { dispatch } = useContext(UserContext);

    //Load user selecter
    useEffect(() => {
        HttpConnection(HttpOperations.users,
            result => setItems(result.filter(item => item.type.id === 2)),
            error => console.log(error), HttpMethod.get)
    }, []);

    //Set default value
    useEffect(() => setDriverId(item.idUser), [item.idUser]);

    const _sendRequest = () => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => {
            let op = item.idUser ? HttpOperations.desass : `${HttpOperations.assign}/${driverId}`;
            setIsSending(true);
            HttpConnection(`${HttpOperations.users}/${HttpOperations.order}/${item.id}/${op} `,
                result => {
                    setItem({ ...item, idUser: item.idUser ? null : driverId })
                    setIsSending(false);
                },
                error => {
                    console.log(error)
                    setIsSending(false);
                }, HttpMethod.patch)
        };
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    return <Card shadowOn width="12">
        <ContainerFlex width="12" pv="20" ph="32" justify="space-between" align="center">
            <Container>
                <Container width="12" textAlign="start" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgDriver" />} />
                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgDriverText" />} />
            </Container>
        </ContainerFlex>

        <Container width="12" color="color-gray-dark" height="1px" />
        <ContainerFlex width="12" ph="32" pv="20" align="flex-end">
            <Container width="12" lg="wrap" pr="8">
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <select value={driverId ?? ""} onChange={(e) => setDriverId(e.target.value)} style={{ padding: "8px" }} disabled={item.idUser}>
                        <option value="" children="Selecciona" />
                        {items.map(item => <option value={item.id} key={item.id} children={`${item.fullname} - ${item.username} - ${item.id}`} />)}
                    </select>
                </Card>
            </Container>
            {
                isSending ? <ContainerFlex mt="16" align="flex-end" children={<SpinnerLoader size="41px" />} />
                    :
                    item.idUser ? <Button onClick={_sendRequest} mt="16" pv="8" ph="16" color="color-red" hoverColor="color-red-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionDesa" />} />
                        : <Button onClick={_sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionAssign" />} />
            }
        </ContainerFlex>
    </Card>
}

const PointItemView = ({ item, show, deleteItem }) => {
    return <Container width="12">
        <ContainerFlex align="flex-start" ph="32" pv="10">
            <Container width="wrap">
                <Container fontWeight="500" fontSize="20px" children={`${item.tipo.descripcion}`} />
                <Container fontWeight="500" fontSize="10px" children={`${item.estado.descripcion}`} />

                <ContainerFlex width="12" fontSize="14px" fontColor="color-gray" mt="8" >
                    <Container width="wrap" children={`${item.localizacion}`} />
                    <Container pr="16" children={`${format(new Date(item.fechaInicio), "dd/MM/yyyy HH:mm")}`} />
                </ContainerFlex>
            </Container>
            <Button onClick={() => show(item)} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
            <Button onClick={() => deleteItem(item)} px="10" ml="10" color="color-red" hoverColor="color-red-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiTrashCanOutline} size="20px" />} />
        </ContainerFlex>
    </Container>
}

const PointsListView = ({ item }) => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.order}/${item.id}`,
            result => setItems(result.puntos),
            error => console.log(error), HttpMethod.get)
    }, [item.id])

    const showItem = (point = { ...PointDefault }) => {
        point.idParteOperaciones = item.id;
        history("/point", { state: { item: point } });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.point}/${item.id}`,
            () => _refreshData(),
            error => console.log(error), HttpMethod.delete);
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const _refreshData = () => {
        HttpConnection(`${HttpOperations.order}/${item.id}`,
            result => setItems(result.puntos),
            error => console.log(error), HttpMethod.get)
    }

    return <Card shadowOn width="12" mv="20">
        <ContainerFlex width="12" pv="20" ph="32" justify="space-between" align="center">
            <Container>
                <Container width="12" textAlign="start" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgPoints" />} />
                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgPointsText" />} />
            </Container>
            <Button onClick={() => showItem()} mh="4" pv="8" ph="16" color={"color-primary"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionNew" />} />
        </ContainerFlex>

        <Container width="12" color="color-gray-dark" height="1px" />
        <Container width="12" pv="10" maxheight="825px" overflowY="auto" children={items.map((item, index) => <PointItemView key={index} item={item} show={showItem} deleteItem={deleteItem} />)} />
    </Card>
}

const OrderLocationItem = ({ item }) => {
    const _show = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${item.latitud},${item.longitud}`, "_blanck");
    }

    return <Container width="12">
        <ContainerFlex align="flex-start" ph="32" pv="10">
            <Container width="wrap">
                <Container fontWeight="500" fontSize="20px" children={`${item.latitud}, ${item.longitud}`} />
                <Container fontWeight="500" fontSize="10px" children={`${format(new Date(item.fecha), "dd/MM/yyyy HH:mm")}`} />
            </Container>
            <Button onClick={_show} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
        </ContainerFlex>
    </Container>
}

const OrderLocationsView = ({ item }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(`${HttpOperations.order}/${item.id}/${HttpOperations.locations}`,
            result => setItems(result),
            error => console.log(error), HttpMethod.get)
    }, [item.id])

    return <Card flex shadowOn width="12" pv="20" mv="20" align="flex-end">
        <Container width="wrap" ph="32">
            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgLocations" />} />
            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgLocationsText" />} />
        </Container>
        <Container width="12" color="color-gray-dark" height="1px" mv="20" />
        <ContainerFlex width="12" justify="flex-end" maxheight="400px" overflowY="auto">
            {items.length > 0 ? items.map((item) => <OrderLocationItem key={item.id} item={item} />) : <Container width="12" textAlign="center" fontSize="20px" children={<TextLocalized children="msgEmptyData" />} />}
        </ContainerFlex>
    </Card>
}

const OrderDetailView = () => {
    const [formData, setFormData] = useState();
    const [isSending, setIsSending] = useState(false);

    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [datePrevious, setDatePrevious] = useState("");

    const { dispatch } = useContext(UserContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log(location.state)
        if (location.state.item.id) {
            HttpConnection(`${HttpOperations.order}/${location.state.item.id}`,
                result => _setDefaultValues(result),
                error => console.log(error), HttpMethod.get)
        } else {
            _setDefaultValues(location.state.item);
        }
    }, [location.state])

    const _setDefaultValues = (result) => {
        if (result.fechaInicio !== "" && result.fechaFin !== "") {
            setDateStart(new Date(result.fechaInicio));
            setDateEnd(new Date(result.fechaFin));
        }

        if (result.citaPrevia && result.citaPrevia !== "") {
            let date = new Date();
            let pick = result.citaPrevia.split(":");
            date.setHours(pick[0]);
            date.setMinutes(pick[1]);
            setDatePrevious(date)
        }
        setFormData(result);
    }

    const formRequest = () => {

        console.log(formData)


        if (formData.identificador !== "" && //formData.matriculaPrimera !== "" && formData.matriculaSegunda !== "" &&
            formData.idNotaCarga !== "" && formData.fechaInicio !== "" && formData.fechaFin !== "" &&
            formData.origen !== "" && formData.destino !== "" && formData.temperatura !== "" &&
            formData.numPalets !== "") {

            setIsSending(true)
            HttpConnection(formData.id ? `${HttpOperations.order}/${formData.id}` : `${HttpOperations.note}/${formData.idNotaCarga}/${HttpOperations.order}`,
                result => {
                    setIsSending(false)
                    //Set default
                    if (!formData.id) {
                        setFormData({ idNotaCarga: formData.idNotaCarga, ...OrderDefault });
                        setDateStart("");
                        setDateEnd("");
                        setDatePrevious("");
                    }
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => {
                    setIsSending(false)
                    console.log(error)
                }, formData.id ? HttpMethod.put : HttpMethod.post, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <ContainerFlex width="12" lg="wrap" justify="flex-end">
                <Button onClick={() => history(-1)} flex justify="center" align="center" px="10" mh="32" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" maxwidth="50px" height="50px" radius="50%" children={<Icon path={mdiChevronLeft} size="30px" />} />
            </ContainerFlex>
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgOrder" />} />
            <Container width="12" lg="wrap" />
        </ContainerFlex>

        <ContainerFlex width="12" justify="center" align="top" pv="8">
            {
                formData ?
                    <Fragment>
                        <OrderFormView formData={formData} setFormData={setFormData} isSending={isSending} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} datePrevious={datePrevious} setDatePrevious={setDatePrevious} sendRequest={formRequest} />
                        {
                            formData.id ?
                                <Container width="12" lg="6" px="12">
                                    <DriverSetView item={formData} setItem={setFormData} />
                                    <PointsListView item={formData} />
                                    <OrderLocationsView item={formData} />
                                </Container>
                                : null
                        }
                    </Fragment>
                    : null
            }
        </ContainerFlex>

    </Container>
};

export default OrderDetailView;