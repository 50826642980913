
import { mdiInformation, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NoteDefault } from '../../../constants/model';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, SpinnerLoader, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';

import DatePicker, { registerLocale } from "react-datepicker";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

import '../../../css/calendar.css';
import { ModalViewDefault } from '../../util/ModalUtil';

registerLocale('es', es)

export const NoteFormView = ({ formData, setFormData, isSending, dateStart, setDateStart, dateEnd, setDateEnd, sendRequest }) => {

    const _changeDateStart = (date) => {
        setDateStart(date);
        setFormData({ ...formData, fechaInicio: date.getTime() });
    }
    const _changeDateEnd = (date) => {
        setDateEnd(date);
        setFormData({ ...formData, fechaFin: date.getTime() });
    }

    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    return <Container width="12" lg="4" px="12">
        <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
            <Container width="wrap">
                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgNewNote" />} />
                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgNewNoteText" />} />
            </Container>
            <ContainerFlex width="12" pt="16" justify="flex-end">

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgIdentifier" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input name="identificador" value={formData.identificador} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPedido" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numPedido" value={formData.numPedido} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumNota" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numNota" value={formData.numNota} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgCommodity" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input name="mercancia" value={formData.mercancia} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgCustomer" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input name="cliente" value={formData.cliente} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgBussiness" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input name="razon" value={formData.razon} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={dateStart}
                                minDate={new Date()}
                                onChange={_changeDateStart}
                                className="input-date"
                                name="fechaInicio"
                                value={dateStart}
                            />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDateEnd" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                showPopperArrow={false}
                                timeIntervals={1}
                                selected={dateEnd}
                                minDate={new Date()}
                                onChange={_changeDateEnd}
                                className="input-date"
                                name="fechaFin"
                                value={dateEnd}
                            />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLocationStart" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="origen" value={formData.origen} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLocationEnd" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="text" name="destino" value={formData.destino} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12">
                    <Container width="6" pr="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgTemperature" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="temperatura" value={formData.temperatura} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                    <Container width="6" pl="4">
                        <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgNumPalets" />} mv="8" />
                        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                            <input type="number" name="numPalets" value={formData.numPalets} onChange={_onChange} style={{ padding: "8px" }} />
                        </Card>
                    </Container>
                </ContainerFlex>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgExchange" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <select name="intercambio" value={formData.intercambio ?? ""} onChange={_onChange} style={{ padding: "8px" }}>
                        <option children="Selecciona" />
                        <option value={true} children="Si" />
                        <option value={false} children="No" />
                    </select>
                </Card>

                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgObservations" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <textarea rows="5" name="observaciones" value={formData.observaciones ?? ""} onChange={_onChange} style={{ padding: "8px" }} />
                </Card>
                {
                    isSending ? <Container mt="16" children={<SpinnerLoader />} />
                        : <Button onClick={sendRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSend" />} />
                }
            </ContainerFlex>
        </Card>
    </Container>
}

const NoteItemView = ({ item, show, deleteItem }) => {

    return <ContainerFlex align="flex-start" ph="32" pv="10">
        <Container width="wrap">
            <Container fontWeight="500" fontSize="20px" children={`${item.identificador}`} />
            <Container fontWeight="500" fontSize="10px" children={`Cliente: ${item.cliente ?? "No especificado"}`} />
            <Container fontWeight="500" fontSize="10px" children={`Razón: ${item.razon ?? "No especificado"}`} />
            <Container fontWeight="500" fontSize="10px" children={`Mercancía: ${item.mercancia ?? "No especificada"}`} />


            <ContainerFlex width="12" fontSize="14px" color="color-primary-light" fontColor="color-black" mt="4" pv="4" >
                <Container width="6" children={`Nº pedido: ${item.numPedido}`} />
                <Container width="6" children={`Nº nota: ${item.numNota}`} />
            </ContainerFlex>

            <ContainerFlex width="12" fontSize="14px" fontColor="color-gray" pv="4" >
                <Container width="6" children={`Org: ${item.origen}`} />
                <Container width="6" children={`Dest: ${item.destino}`} />
            </ContainerFlex>

            <ContainerFlex width="12" fontSize="14px" color="color-primary-light" fontColor="color-black" pv="4" >
                <Container width="6" children={`Ini: ${format(new Date(item.fechaInicio), "dd/MM/yyyy HH:mm")}`} />
                <Container width="6" children={`Fin: ${format(new Date(item.fechaFin), "dd/MM/yyyy HH:mm")}`} />
            </ContainerFlex>
        </Container>
        <Button onClick={() => show(item)} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
        <Button onClick={() => deleteItem(item)} px="10" ml="10" color="color-red" hoverColor="color-red-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiTrashCanOutline} size="20px" />} />
    </ContainerFlex>
}

const NoteManager = () => {
    const [formData, setFormData] = useState({ ...NoteDefault });
    const [isSending, setIsSending] = useState(false);

    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");

    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.note}/page?page=0&count=100`,
            result => setItems(result.reverse()),
            error => console.log(error), HttpMethod.get)
    }, [])

    const _refreshData = () => HttpConnection(`${HttpOperations.note}/page?page=0&count=5`,
        result => setItems(result),
        error => console.log(error), HttpMethod.get)

    const createNoteRequest = () => {
        if (formData.identificador !== "" && formData.numPedido !== "" && formData.numNota !== "" &&
            formData.fechaInicio !== "" && formData.fechaFin !== "" &&
            formData.origen !== "" && formData.destino !== "" && formData.numPalets !== "") {

            setIsSending(true);
            HttpConnection(HttpOperations.note,
                result => {
                    setIsSending(false);
                    setFormData({ ...NoteDefault });
                    setDateStart("")
                    setDateEnd("")

                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                    _refreshData();
                },
                error => {
                    setIsSending(false);
                    console.log(error)
                }, HttpMethod.post, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.note}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                _refreshData();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const showItem = (item) => history("/note", { state: { item: item } });

    return <ContainerFlex width="12" justify="center" align="top" pv="8">

        <NoteFormView formData={formData} setFormData={setFormData} isSending={isSending}
            dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd}
            sendRequest={createNoteRequest} />

        <Container width="12" lg="6" px="12">
            <Card shadowOn width="12">
                <ContainerFlex flex width="12" pv="20" ph="32" align="flex-start">
                    <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgNotes" />} />
                    <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgNotesText" />} />
                </ContainerFlex>
                <Container width="12" color="color-gray-dark" height="1px" />
                <Container width="12" pv="10" maxheight="825px" overflowY="auto" children={items.map((item, index) => <NoteItemView key={index} item={item} show={showItem} deleteItem={deleteItem} />)} />
            </Card>
        </Container>
    </ContainerFlex>
}

const NoteListView = () => {

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgNotes" />} />
        </ContainerFlex>

        <NoteManager />
    </Container>
};

export default NoteListView;