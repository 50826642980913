import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Container, ContainerFlex, Card, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';

import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import '../../../css/calendar.css';
import { NoteFormView } from './NoteListView';
import { OrderDefault } from '../../../constants/model';
import { OrderItemView } from '../orders/OrderListView';
import { ModalViewDefault } from '../../util/ModalUtil';

registerLocale('es', es)

const OrdersListView = ({ item }) => {

    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState(0);
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.note}/${item.id}`,
            result => setItems(result.partes.reverse()),
            error => console.log(error), HttpMethod.get)
    }, [item.id])

    const showItem = (order = { ...OrderDefault }) => {
        order.idNotaCarga = item.id;
        history("/order", { state: { item: order } });
    }

    const deleteItem = (order) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${HttpOperations.order}/${order.id}`,
            () => _refreshData(),
            error => console.log(error), HttpMethod.delete);
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const _refreshData = () => {
        HttpConnection(`${HttpOperations.note}/${item.id}`,
            result => setItems(result.partes),
            error => console.log(error), HttpMethod.get)
    }

    return <Container width="12" lg="6" px="12">
        <Card shadowOn width="12">
            <ContainerFlex width="12" pv="20" ph="32" align="flex-start">
                <ContainerFlex width="12" justify="space-between" align="center">
                    <Container>
                        <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgOrders" />} />
                        <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgOrdersText" />} />
                    </Container>
                    <Button onClick={() => showItem()} mh="4" pv="8" ph="16" color={"color-primary"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionNew" />} />
                </ContainerFlex>

                <ContainerFlex width="12" mt="16">
                    <Button onClick={() => setFilter(0)} mh="4" pv="8" ph="16" color={filter === 0 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAll" />} />
                    <Button onClick={() => setFilter(1)} mh="4" pv="8" ph="16" color={filter === 1 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgNotAssign" />} />
                    <Button onClick={() => setFilter(2)} mh="4" pv="8" ph="16" color={filter === 2 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAssign" />} />
                    <Button onClick={() => setFilter(3)} mh="5" pv="8" ph="16" color={filter === 3 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAccepted" />} />
                    <Button onClick={() => setFilter(4)} mh="4" pv="8" ph="16" color={filter === 4 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgRejected" />} />
                </ContainerFlex>
            </ContainerFlex>

            <Container width="12" color="color-gray-dark" height="1px" />
            <Container width="12" pv="10" maxheight="825px" overflowY="auto" children={items.filter(item => item.idEstadoParte === filter || filter === 0).map((item, index) => <OrderItemView key={index} item={item} show={showItem} deleteItem={deleteItem} />)} />
        </Card>
    </Container>
}

const NoteDetailView = () => {
    const [formData, setFormData] = useState();
    const [isSending, setIsSending] = useState(false);

    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");

    const { dispatch } = useContext(UserContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
        let item = location.state.item;

        setFormData(item);
        setDateStart(new Date(item.fechaInicio));
        setDateEnd(new Date(item.fechaFin));
    }, [location.state])

    const updateNoteRequest = () => {
        if (formData.id.length !== "" && formData.identificador !== "" && formData.numPedido !== "" && formData.numNota !== "" &&
            formData.fechaInicio !== "" && formData.fechaFin !== "" &&
            formData.origen !== "" && formData.destino !== "" &&
            formData.numPalets !== "") {

            setIsSending(true);
            HttpConnection(`${HttpOperations.note}/${formData.id}`,
                result => {
                    setIsSending(false);
                    setFormData(result);
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                },
                error => {
                    setIsSending(false)
                    console.log(error)
                }, HttpMethod.put, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <ContainerFlex width="12" lg="wrap" justify="flex-end">
                <Button onClick={() => history(-1)} flex justify="center" align="center" px="10" mh="32" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" maxwidth="50px" height="50px" radius="50%" children={<Icon path={mdiChevronLeft} size="30px" />} />
            </ContainerFlex>
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgNote" />} />
            <Container width="12" lg="wrap" />
        </ContainerFlex>

        <ContainerFlex width="12" justify="center" align="top" pv="8">
            {
                formData ?
                    <Fragment>
                        <NoteFormView formData={formData} setFormData={setFormData} isSending={isSending} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} sendRequest={updateNoteRequest} />
                        <OrdersListView item={formData} />
                    </Fragment>
                    : null
            }
        </ContainerFlex>

    </Container>
};

export default NoteDetailView;