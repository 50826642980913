
import { getUserToken, updateStorageUser, getStorageUser } from './UserUtil';

export const HttpOperations = {
    login: "auth/token",
    account: "account",
    
    bussiness: "empresa",
    users: "usuario",
    note: "nota",
    order: "parte",
    point: "punto",
    assign: "asignar",
    desass: "desasignar",
    files: "archivos",
    locations: "geolocalizacion",
    subscriptions: "tiposuscripcion",
    password: "password",
    refuel: "refuel",
    link: "link",
    file: "archivo",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401) && !checkingAuth) {

            var auxUser = getStorageUser();

            console.log(auxUser)

            const params = {
                "username": auxUser.usuario.username,
                "password": auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.login, (item) => {
                updateStorageUser(item);
                HttpConnection(operation, success, error, method, data, false);
            },
                (errorCode) => error(errorCode),
                HttpMethod.post, params, true);
        } else {
            error(request.status);
            console.log(request.status);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

export const HttpImageConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401) && !checkingAuth) {

            var auxUser = getStorageUser();

            console.log(auxUser)

            const params = {
                "username": auxUser.usuario.username,
                "password": auxUser.usuario.password,
            }

            HttpConnection(HttpOperations.login, (item) => {
                updateStorageUser(item);
                HttpConnection(operation, success, error, method, data, false);
            },
                (errorCode) => error(errorCode),
                HttpMethod.post, params, true);
        } else {
            error(request.status);
            console.log(request.status);
        }
    } else {
        try {
            item = await request.text();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

export const HttpFormData = async (operation, success, error, data, params = [], checkingAuth = false) => {
    let token = getUserToken();

    var formData = new FormData()
    params.forEach((param) => formData.append(param.key, param.param));

    if (data)
        formData.append("json", JSON.stringify(data));

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: HttpMethod.post,
        body: formData,
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
        },
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401 || request.status === 403) && !checkingAuth) {
            var auxUser = getStorageUser();
            let params = { "email": auxUser.email, "password": auxUser.password }

            HttpConnection(HttpOperations.login, (item) => {
                updateStorageUser(item);
                HttpConnection(operation, success, error, HttpMethod.post, data, params, false);
            },
                (errorCode) => error(errorCode), HttpMethod.post, params, true);
        } else {
            error(request.status);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

//wifi: 192.168.5.39
//movil: 172.20.10.8
const debugMode = false;
const serverUrlEndpoint = debugMode ? "http://192.168.5.38:8084/metotrans/api/" : "https://api.metotrans.com/metotrans/api/";
export const imageURL = debugMode ? "" : "";
