import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Container, ContainerFlex, SpinnerLoader, TextLocalized } from '../styled/Styled';
import { mdiChevronLeft, mdiTruck } from '@mdi/js';
import Icon from '@mdi/react'
import { BusinessDefault, cardDefault, UserDefault } from '../../constants/model';
import { HttpConnection, HttpMethod, HttpOperations } from '../util/HttpConnection';
import { verifyEmailFormat } from '../util/UserUtil';
import { UserAction, UserContext } from '../providers/UserProvider';

export const InputRegister = ({ label, name, value = "", onChange }) => {
    return <Fragment>
        <Container textAlign="left" fontWeight="bold" children={<TextLocalized children={label} />} mv="8" />
        <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px"
            children={<input name={name} value={value} onChange={onChange} style={{ padding: "8px" }} />} />
    </Fragment>
}

export const UserRegisterView = ({ formData, setFormData }) => {
    const onchange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, });

    return <Fragment>
        <Container width="12" lg="6" ph="8">
            <InputRegister label="msgEmail" name="username" value={formData.username} onChange={onchange} />
            <InputRegister label="msgPassword" name="password" value={formData.password} onChange={onchange} />
        </Container>
        <Container width="12" lg="6" ph="8">
            <InputRegister label="msgFullName" name="fullname" value={formData.fullname} onChange={onchange} />
            <InputRegister label="msgPhone" name="phone" value={formData.phone} onChange={onchange} />
        </Container>
    </Fragment>
}

const SuscriptionItem = ({ formData, setFormData, item }) => {
    const getColor = () => item.id === 1 ? "color-gold" : item.id === 2 ? "color-silver" : "color-bronze";

    return <Card onClick={() => setFormData(item.id)} flex align="flex-start" width="12" mb="16" shadowOn={false} border="solid" borderColor="color-black" borderWidth={"1px"} px="16"
        color={formData === item.id ? "color-black" : "color-white"}>
        <Card flex border="solid" borderColor="color-black" borderWidth="1px" color={getColor()} px="8" radius="12px" fontColor="color-white" children={<Icon path={mdiTruck} size={1} />} />
        <Container width="wrap" ph="16">
            <Container textAlign="start" fontWeight="bold" fontColor={formData === item.id ? "color-white" : "color-black"} children={<TextLocalized children={`msgSubscription${item.id}`} />} />
            <ContainerFlex fontSize="12px" fontColor={formData === item.id ? "color-white" : "color-gray"} children={`Conductores disponibles: ${item.limite}`} />
        </Container>
        <Container flex border="solid" fontColor={"color-black"} fontWeight="bold" px="8" children={`${item.importe} €`} />

    </Card >
}

export const BusinessRegisterView = ({ formData, setFormData }) => {
    const onchange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, });

    return <Fragment>
        <Container width="12" lg="6" ph="8">
            <InputRegister label="msgNameEntity" name="descripcion" value={formData.descripcion} onChange={onchange} />
            <InputRegister label="msgRazonEntity" name="razon" value={formData.razon} onChange={onchange} />
            <InputRegister label="msgCIF" name="cif" value={formData.cif} onChange={onchange} />
            <InputRegister label="msgAddress" name="direccion" value={formData.direccion} onChange={onchange} />
            <InputRegister label="msgPostalCode" name="cp" value={formData.cp} onChange={onchange} />
            <InputRegister label="msgHistoryDays" name="diasHistorico" value={formData.diasHistorico} onChange={onchange} />
        </Container>
        <Container width="12" lg="6" ph="8">
            <InputRegister label="msgCity" name="localidad" value={formData.localidad} onChange={onchange} />
            <InputRegister label="msgProvince" name="provincia" value={formData.provincia} onChange={onchange} />
            <InputRegister label="msgCountry" name="pais" value={formData.pais} onChange={onchange} />
            <InputRegister label="msgPhone" name="telefono" value={formData.telefono} onChange={onchange} />
            <InputRegister label="msgContactEntity" name="contacto" value={formData.contacto} onChange={onchange} />
        </Container>
    </Fragment>
}

export const BusinessSubscriptionView = ({ formData, setFormData }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(HttpOperations.subscriptions, result => setItems(result), error => console.log(error), HttpMethod.get)
    }, [])

    return items.map(val => <SuscriptionItem key={val.id} formData={formData} setFormData={setFormData} item={val} />);
}

export const BusinessCardView = ({ formData, setFormData }) => {
    const onchange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, });

    return <Fragment>
        <InputRegister label="msgCardNumber" name="numero" value={formData.numero} onChange={onchange} />
        <ContainerFlex width="12">
            <Container width="4">
                <InputRegister label="msgMonth" name="expMonth" value={formData.expMonth} onChange={onchange} />
            </Container>
            <Container width="4" ph="16">
                <InputRegister label="msgYear" name="expYear" value={formData.expYear} onChange={onchange} />
            </Container>
            <Container width="4">
                <InputRegister label="msgCVC" name="cvc" value={formData.cvc} onChange={onchange} />
            </Container>
        </ContainerFlex>
        <InputRegister label="msgTitular" name="titular" value={formData.titular} onChange={onchange} />
    </Fragment>
}

const RegisterView = () => {
    const [user, setUser] = useState({ ...UserDefault });
    const [business, setBusiness] = useState({ ...BusinessDefault });
    const [card, setCard] = useState({ ...cardDefault })
    const [subscription, setSubscription] = useState();

    //Sending flag
    const [isSending, setIsSending] = useState(false);

    let history = useNavigate();
    let { dispatch } = useContext(UserContext);

    const requestAPI = () => {
        if (user.username !== "" && user.password !== "" && user.fullname !== "" && user.phone !== "" &&
            business.descripcion !== "" && business.cif !== "" && business.razon !== "" && business.direccion !== "" &&
            business.localidad !== "" && business.cp !== "" && business.provincia !== "" && business.pais !== "" &&
            business.telefono !== "" && business.contacto !== "" && business.diasHistorico !== "" && 
            subscription && card.numero !== "" &&
            card.expMonth !== "" && card.expYear !== "" && card.cvc !== "" && card.titular !== "") {
            if (verifyEmailFormat(user.username)) {
                setIsSending(true) //Show loader
                let data = {
                    empresa: { ...business, suscripcion: { id: subscription } },
                    tarjeta: card,
                    admin: user,
                };
                sendAccount(data);
            } else {
                dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    //Create business account
    const sendAccount = (data) => HttpConnection(`${HttpOperations.bussiness}/alta`,
        (result) => {
            //Send subscription
            dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successAccount" } })
            //Reset form
            setUser(UserDefault);
            setBusiness(BusinessDefault);
            setIsSending(false) //Hide loader
            setTimeout(() => history("/"), 2000);
        }, error => {
            console.log(error);
            setIsSending(false) //Hide loader
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorCreateAccount" } })
        }, HttpMethod.post, data);

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center" mb="16">
            <ContainerFlex width="12" lg="wrap" justify="flex-end">
                <Button onClick={() => history("/")} flex justify="center" align="center" px="10" mh="32" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" maxwidth="50px" height="50px" radius="50%" children={<Icon path={mdiChevronLeft} size="30px" />} />
            </ContainerFlex>
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgAccountEntity" />} />
            <Container width="12" lg="wrap" />
        </ContainerFlex>

        <ContainerFlex width="12" justify="center" align="center">
            <ContainerFlex width="12" lg="7" ph="16">
                <Container width="12" px="16" mv="16" textAlign="center" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgEntityData" />} />
                <ContainerFlex width="12" children={<BusinessRegisterView formData={business} setFormData={setBusiness} />} />
                <Container width="12" px="16" mv="16" textAlign="center" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgUserData" />} />
                <ContainerFlex width="12" children={<UserRegisterView formData={user} setFormData={setUser} />} />

                <Container width="12" lg="6" ph="8">
                    <Container width="12" px="16" mv="16" textAlign="center" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgPaymentMethod" />} />
                    <BusinessCardView formData={card} setFormData={setCard} />
                </Container>

                <Container width="12" lg="6" ph="8">
                    <Container width="12" px="16" mv="16" textAlign="center" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgSubscriptionType" />} />
                    <BusinessSubscriptionView formData={subscription} setFormData={setSubscription} />
                </Container>

                {
                    isSending
                        ? <SpinnerLoader />
                        : <Button onClick={requestAPI} width="12" mv="20" px="8" radius="20px" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionCreateAccount" />} />
                }
            </ContainerFlex>
        </ContainerFlex>

    </Container>
};

export default RegisterView;