import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { OrderDefault } from '../../../constants/model';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { format } from 'date-fns'
import { mdiInformation, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { LanguageContext } from '../../providers/LanguageProvider';

export const OrderItemView = ({ item, show, deleteItem }) => {

    const textContext = useContext(LanguageContext);
    const _getState = (stateId) => stateId === 1 ? textContext.dictionary["msgNotAssign"] : stateId === 2 ? textContext.dictionary["msgAssign"] : stateId === 3 ? textContext.dictionary["msgAccepted"] : stateId === 4 ? textContext.dictionary["msgRejected"] : textContext.dictionary["msgEnded"];

    return <ContainerFlex align="flex-start" ph="32" pv="10">
        <Container width="wrap">
            <Container fontWeight="500" fontSize="20px" children={`${item.identificador}`} />
            <Container fontWeight="500" fontSize="10px" children={`${_getState(item.idEstadoParte)}`} />

            <ContainerFlex width="12" fontSize="14px" fontColor="color-gray" pv="4" >
                <Container width="6" children={`Org: ${item.origen}`} />
                <Container width="6" children={`Dest: ${item.destino}`} />
            </ContainerFlex>

            <ContainerFlex width="12" fontSize="14px" color="color-primary-light" fontColor="color-black" pv="4" >
                <Container width="6" children={`Ini: ${format(new Date(item.fechaInicio), "dd/MM/yyyy HH:mm")}`} />
                <Container width="6" children={`Fin: ${format(new Date(item.fechaFin), "dd/MM/yyyy HH:mm")}`} />
            </ContainerFlex>
        </Container>
        <Button onClick={() => show(item)} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
        <Button onClick={() => deleteItem(item)} px="10" ml="10" color="color-red" hoverColor="color-red-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiTrashCanOutline} size="20px" />} />
    </ContainerFlex>
}

const OrderListView = () => {

    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);
    const [filter, setFilter] = useState(1);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.order}/page/company?page=0&count=50`,
            result => setItems(result.reverse()),
            error => console.log(error), HttpMethod.get)
    }, [])

    const _refreshData = () => HttpConnection(`${HttpOperations.order}/page/company?page=0&count=50`,
        result => setItems(result.reverse()),
        error => console.log(error), HttpMethod.get)

    const deleteItem = (item) => {
        HttpConnection(`${HttpOperations.order}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                _refreshData();
            },
            error => console.log(error), HttpMethod.delete)
    }

    const showItem = (item = { ...OrderDefault }) => history("/order", { state: { item: item } });

    return <Container width="12" minheight="calc(100vh - 255px)" >
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgOrders" />} />
        </ContainerFlex>

        <ContainerFlex width="12" px="12" justify="center">
            <Card shadowOn width="12" lg="8">
                <ContainerFlex width="12" pv="20" ph="32" align="flex-start">
                    <ContainerFlex width="12" justify="space-between" align="center">
                        <Container>
                            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgOrders" />} />
                            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgOrdersListText" />} />
                        </Container>
                        <Button display="none" onClick={() => showItem()} mh="4" pv="8" ph="16" color={"color-primary"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionNew" />} />
                    </ContainerFlex>

                    <ContainerFlex width="12" mt="16">
                        <Button onClick={() => setFilter(1)} mh="4" pv="8" ph="16" color={filter === 1 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgNotAssign" />} />
                        <Button onClick={() => setFilter(2)} mh="4" pv="8" ph="16" color={filter === 2 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAssign" />} />
                        <Button onClick={() => setFilter(3)} mh="5" pv="8" ph="16" color={filter === 3 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAccepted" />} />
                        <Button onClick={() => setFilter(4)} mh="4" pv="8" ph="16" color={filter === 4 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgRejected" />} />
                    </ContainerFlex>
                </ContainerFlex>
                <Container width="12" color="color-gray-dark" height="1px" />
                <Container width="12" pv="10" maxheight="425px" overflowY="auto" children={items.filter(item => item.idEstadoParte === filter || filter === 0).map((item, index) => <OrderItemView key={index} item={item} show={showItem} deleteItem={deleteItem} />)} />
            </Card>
        </ContainerFlex>

    </Container>
};

export default OrderListView;