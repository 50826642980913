import React, { useContext, useState } from 'react';
import { UserDefault } from '../../constants/model';
import { UserAction, UserContext } from '../providers/UserProvider';
import { HttpConnection, HttpMethod, HttpOperations } from '../util/HttpConnection';
import { verifyEmailFormat } from '../util/UserUtil';
import { Button, Card, Container, ContainerFlex, SpinnerLoader, TextLocalized } from '../styled/Styled';

const LoginView = () => {

    const [formData, setFormData] = useState({ ...UserDefault })

    //Sending flag
    const [isSending, setIsSending] = useState(false);

    let { dispatch } = useContext(UserContext);

    const _onchange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, });

    const requestAPI = () => {
        if (formData.username.length > 0 && formData.password.length > 0) {
            if (verifyEmailFormat(formData.username)) {
                setIsSending(true) //Show loader
                HttpConnection(HttpOperations.login,
                    (result) => {
                        setIsSending(false) //Hide loader
                        //Set pass
                        result.usuario.password = formData.password;
                        dispatch({ action: UserAction.login, data: result })
                    }, error => {
                        console.log(error);
                        setIsSending(false) //Hide loader
                        dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorLogin" } })
                    }, HttpMethod.post, formData)
            } else {
                dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    return <Container width="12" minheight="calc(100vh - 255px)">
        <Container width="12" textAlign="center" fontWeight="bold" fontSize="48px" children={<TextLocalized children="msgWelcome" />} />
        <Container width="12" textAlign="center" fontColor="color-gray" fontSize="18px" children={<TextLocalized children="msgWelcomeSub" />} />

        <ContainerFlex width="12" justify="center" pv="50">
            <Container minwidth="400px" maxwidth="400px">
                <Container textAlign="left" fontWeight="bold" children={<TextLocalized children="msgEmail" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input type="email" name="username" value={formData.username} onChange={_onchange} style={{ padding: "8px" }} />
                </Card>
                <Container textAlign="left" fontWeight="bold" children={<TextLocalized children="msgPassword" />} mv="8" />
                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                    <input type="password" name="password" value={formData.password} onChange={_onchange} style={{ padding: "8px" }} />
                </Card>
                {
                    isSending
                        ? <ContainerFlex width="12" mv="20" justify="center" children={<SpinnerLoader />} />
                        : <Button onClick={requestAPI} width="12" mv="20" px="8" radius="20px" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionLogin" />} />
                }
            </Container>
        </ContainerFlex>
    </Container>;
};

export default LoginView;