export const UserDefault = {
    username: "",
    password: "",
    phone: "",
    fullname: "",
    registration: "",
};

export const BusinessDefault = {
    descripcion: "",
    razon: "",
    cif: "",
    direccion: "",
    cp: "",
    localidad: "",
    provincia: "",
    pais: "",
    telefono: "",
    contacto: "",
    diasHistorico: 30
}

export const cardDefault = {
    numero: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    titular: ""
}

export const NoteDefault = {
    identificador: "",
    numPedido: "",
    numNota: "",
    cliente: "",
    razon: "",
    fechaInicio: "",
    fechaFin: "",
    origen: "",
    destino: "",
    temperatura: "",
    numPalets: "",
    intercambio: null,
    observaciones: ""
}

export const OrderDefault = {
    identificador: "",
    fechaInicio: "",
    fechaFin: "",
    origen: "",
    destino: "",
    temperatura: 0,
    numPalets: 0,
    intercambio: null,
    observaciones: "",
    citaPrevia: "",
    matriculaPrimera: "",
    matriculaSegunda: "",
    idNotaCarga: "",
    idUser: ""
}

export const PointDefault = {
    fechaInicio: "",
    fechaRealInicio: "",
    fechaRealFin: "",
    localizacion: "",
    numPaletsEntrega: 0,
    numPaletsRecogida: 0,
    intercambio: "",
    observaciones: "",
    citaPrevia: "",
    idParteOperaciones: "",
    tipo: { id: null }
}

export const UserFile = { nombre: "", url: "" };