import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Container, ContainerFlex, Card, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpFormData, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { getStorageUser } from '../../util/UserUtil';
import { LanguageContext } from '../../providers/LanguageProvider';
import { UserFile } from '../../../constants/model';

const UserRefuelList = () => {
    const [items, setItems] = useState([]);
    const languageContext = useContext(LanguageContext);
    let location = useLocation();

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {
        let user = location.state.user;

        HttpConnection(`${HttpOperations.users}/${user.id}/${HttpOperations.refuel}`,
            response => {
                setItems(response)
            },
            error => {

            }, HttpMethod.get
        );
    }

    return <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
        <Container width="wrap">
            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgUserRefuel" />} />
            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgUserRefuelText" />} />
        </Container>
        {
            items.map(item => {
                return <ContainerFlex key={item.id} width={12} pv="8">
                    <Container fontWeight="500" fontSize="20px" mb="8" children={`${languageContext.dictionary["msgMatRefuel"]}: ${item.matricula}`} />
                    <Container width="12" children={`${languageContext.dictionary["msgDate"]}: ${item.fecha}`} />
                    <Container width="6" children={`${languageContext.dictionary["msgType"]}: ${item.tipo}`} />
                    <Container width="6" children={`${languageContext.dictionary["msgLitres"]}: ${item.litros}`} />
                    <Container width="6" children={`${languageContext.dictionary["msgKm"]}: ${item.km}`} />
                    <Container width="6" children={`${languageContext.dictionary["msgObservations"]}: ${item.observaciones}`} />
                </ContainerFlex>
            })
        }
    </Card>
}

const UserFileForm = () => {

    const [formData, setFormData] = useState({ ...UserFile });
    const [selectedFile, setSelectedFile] = useState(null);
    const { dispatch } = useContext(UserContext);
    let location = useLocation();

    const fileRef = useRef();
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendForm = () => {
        let user = location.state.user;


        if (formData.url !== "" && selectedFile != null) {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOptionFile" } })
        } else if (formData.url !== "") {
            //Send link
            HttpConnection(`${HttpOperations.users}/${user.id}/${HttpOperations.link}`,
                response => {
                    //Notify action success
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successSend" } })
                    //Reset form
                    setFormData({ ...UserFile });
                },
                error => {
                    dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorSend" } })
                }, HttpMethod.post, formData
            );
        } else if (selectedFile != null) {

            let params = [
                { key: "name", param: formData.nombre },
                { key: "file", param: selectedFile }
            ]

            //Send file
            HttpFormData(`${HttpOperations.users}/${user.id}/${HttpOperations.file}`,
                response => {
                    //Notify action success
                    dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successSend" } })
                    //Reset form
                    setFormData({ ...UserFile });

                    setSelectedFile(null);
                    fileRef.current.value = null;
                },
                error => {
                    dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorSend" } })
                }, null, params,
            );
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const deleteFile = () => {
        fileRef.current.value = null;
        setSelectedFile(null)
    }

    return <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
        <Container width="wrap">
            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgInbox" />} />
            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgInboxText" />} />
        </Container>
        <ContainerFlex width="12" pt="16" justify="flex-end">
            <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgCustomName" />} mv="8" />
            <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                <input name="nombre" value={formData.nombre} onChange={onChange} style={{ padding: "8px" }} />
            </Card>
            <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgLink" />} mv="8" />
            <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                <input name="url" value={formData.url} onChange={onChange} style={{ padding: "8px" }} />
            </Card>
            <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgAttachment" />} mv="8" />
            <ContainerFlex width="12">

                <input ref={fileRef} name="file" onChange={handleFileChange} type='file' hidden />

                {
                    fileRef.current?.files.length > 0 ?
                        <Container pv="8" pr="8" width="wrap" children={fileRef.current.files[0].name} />
                        : null
                }

                {
                    fileRef.current?.files.length > 0 ?
                        <Button onClick={deleteFile} pv="8" ph="16" color="color-red" hoverColor="color-red-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionDelete" />} /> :
                        <Button onClick={() => fileRef.current.click()} pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionNew" />} />
                }

            </ContainerFlex>
            <Button onClick={sendForm} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSend" />} />

        </ContainerFlex>
    </Card>
}

const UserDetailView = () => {
    const [formData, setFormData] = useState();
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
        let user = location.state.user;
        setFormData(user);
    }, [location.state])

    const updateUserRequest = () => {
        if (formData.username.length > 0 && formData.phone.length > 0 && formData.fullname.length > 0) {

            let user = getStorageUser();

            //Update user
            HttpConnection(`${HttpOperations.users}/${formData.id}`,
                result => {
                    setFormData(result);
                    //Update user empresa
                    HttpConnection(`${HttpOperations.bussiness}/${user.usuario.idEmpresa}/user/${formData.id}`,
                        result => {
                            setFormData(result);
                            dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                        },
                        error => console.log(error), HttpMethod.put, formData)
                },
                error => {
                    dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorUpdate" } });
                    console.log(error)
                }, HttpMethod.put, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { color: "color-red", show: true, message: "errorOblData" } })
        }
    }

    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    return <Container width="12" minheight="calc(100vh - 255px)">
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <ContainerFlex width="12" lg="wrap" justify="flex-end">
                <Button onClick={() => history(-1)} flex justify="center" align="center" px="10" mh="32" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" maxwidth="50px" height="50px" radius="50%" children={<Icon path={mdiChevronLeft} size="30px" />} />
            </ContainerFlex>
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgUser" />} />
            <Container width="12" lg="wrap" />
        </ContainerFlex>

        {
            formData ?
                <ContainerFlex width="12" justify="center" align="top" pv="8">
                    <Container width="12" lg="4" px="12">
                        <Card flex shadowOn width="12" pv="20" ph="32" align="flex-end">
                            <Container width="wrap">
                                <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgEditUser" />} />
                                <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgEditUserText" />} />
                            </Container>
                            <ContainerFlex width="12" pt="16" justify="flex-end">
                                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgFullName" />} mv="8" />
                                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                                    <input name="fullname" value={formData.fullname} onChange={_onChange} style={{ padding: "8px" }} />
                                </Card>
                                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgDNI" />} mv="8" />
                                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                                    <input name="username" value={formData.username} onChange={_onChange} style={{ padding: "8px" }} />
                                </Card>
                                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgMatricula" />} mv="8" />
                                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                                    <input name="registration" value={formData.registration} onChange={_onChange} style={{ padding: "8px" }} />
                                </Card>
                                <Container width="12" textAlign="left" fontWeight="bold" children={<TextLocalized children="msgPhone" />} mv="8" />
                                <Card width="12" shadowOn={false} border="solid" borderColor="color-gray" borderWidth="1px">
                                    <input name="phone" value={formData.phone} onChange={_onChange} style={{ padding: "8px" }} />
                                </Card>
                                <Button onClick={updateUserRequest} mt="16" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionUpdateUser" />} />
                            </ContainerFlex>
                        </Card>
                    </Container>

                    <Container width="12" lg="4" px="12">
                        <UserFileForm />
                    </Container>

                    <Container width={12} lg={8} px="12">
                        <UserRefuelList />
                    </Container>

                </ContainerFlex>
                : <Container />
        }

    </Container>
};

export default UserDetailView;